@import './fonts.scss';

body {
  // background-image: url('./assets/images/bg-pattern.webp');
}

.btn-primary {
  border-color: rgba($color-dark, 0.8);
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $color-accent-darker;
}

.product-content {
  background: transparent;
}

@media (min-width: 1200px) {
  .container {
    max-width: 70%;
  }
  .product-detail .container {
    max-width: 80%;
  }
}

.w-40 {
  width: 80%;
  @include media-breakpoint-up(md) {
    width: 33.3333333333%;
  }
}

.product-content h1 {
  @include media-breakpoint-up(md) {
    //@extend .text-truncate;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  //text-transform: uppercase;
  font-weight: 500 !important;
}

#root {
  padding-top: 7rem;
  @include media-breakpoint-down(sm) {
    padding-top: $calculated-navbar-height-sm;
  }
}
.card.bg-light {
  background-color: $gray-800 !important;
  color: $white;
}

.checkout .when-valid {
  background-color: #000;
}

.navbar-light .offcanvas-collapse,
.navbar-dark .offcanvas-collapse {
  background: transparent;

  // &.offcanvas-collapse-right {
  //   background: rgba($color: $black, $alpha: 0.9);
  //   h5 {
  //     color: white;
  //   }
  // }

  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
  }
}

// NAV

.navbar-dark {
  background: transparent !important;
  transition: background 0.2s;
  // background-color: rgba($color: $white, $alpha: 0.9) !important;
  body[data-scrolled] & {
    background-color: $black !important;
  }
}

.navbar-dark .offcanvas-collapse {
  .navbar-nav .nav-item .nav-link {
    //font-family: 'Visual-Regular', $font-family-sans-serif;
    //text-transform: uppercase;
    font-size: 1.3rem;
    background-color: $darkgray;
  }
}

#header > .navbar {
  height: auto;
  background-color: $color-accent;
  @include media-breakpoint-down(sm) {
    padding-top: 0.35rem;
    height: $calculated-navbar-height-sm;
  }

  .main-menu {
    flex-basis: 0 !important;
    flex-grow: 1 !important;
  }

  .navbar-brand {
    font-family: $font-header;
    font-size: $h2-font-size;

    img {
      max-width: 300px;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
    @include media-breakpoint-down(xs) {
      img {
        width: 140px;
      }
    }
  }

  .offcanvas-collapse-left {
    .navbar-nav {
      background-color: rgba($color-dark, 1);
      border-radius: 0px $border-radius $border-radius 0px;

      font-family: $font-header;

      .nav-item {
        .nav-link {
          background-color: transparent;
          color: $color-accent;
          padding: 1.5rem;
          text-transform: uppercase;
          // font-stretch: 150%;
          letter-spacing: 3px;
        }
        &:hover {
          background-color: $color-accent;
          .nav-link {
            color: $color-dark !important;
          }
        }
      }
    }
  }

  .offcanvas-collapse-right {
    background-color: $color-dark;
  }

  .hamburger {
    background-color: transparent !important;
  }
}

// JUMBOTRON
.header-jumbotron {
  // background-color: $dark;
  // color: $white;
  // background-image: url('./assets/images/header.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transform: translateY(-10rem);
  height: 100vh;
  @include media-breakpoint-down(sm) {
    transform: translateY(-6rem);
    height: 60vh;
  }
}

// MAKI

.maki.card {
  background-color: transparent;
  color: $color-accent;

  .card-body {
    padding: $card-spacer-x;
    background: transparent;
    text-align: center;

    .card-title {
      span.title {
        font-weight: 700;
        // font-family: $font-accent;
        font-size: 1.2rem;
        text-transform: none;
        // max-width: 310px;
        @extend .text-truncate;
      }
      span.sub-title {
        font-size: smaller;
        margin-bottom: 5px;
      }
      span.price {
        font-size: 1rem;
      }
    }
  }
}

.table {
  @extend .table-dark;
}

.card,
.navbar-light .offcanvas-collapse {
  color: $black;
}

// Product Detail Page

.product-detail-right {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  .product-supertitle {
    display: none;
  }
  .product-title {
    display: block;
    text-transform: uppercase;
    margin-bottom: 1rem;
    letter-spacing: 2px;
  }
  .product-subtitle {
    font-size: $h1-font-size;
    font-weight: 700;
    font-family: $font-main !important;
    margin-bottom: 1rem;
  }
}

// Footer

.prefooter-wrapper {
  color: $black;
  background-color: $white;

  .prefooter-text {
    padding-left: 0;
    padding-right: 0;
    align-self: center;
    @include media-breakpoint-down(lg) {
      margin-bottom: 2rem;
    }
  }

  .prefooter-icon-list {
    align-items: center;
    flex-wrap: wrap;

    .row {
      justify-content: space-around;

      @include media-breakpoint-down(xs) {
        row-gap: 20px;
      }

      div {
        text-align: center;
        @include media-breakpoint-up(xxl) {
          flex: 0 0 16.6666666667% !important;
          max-width: 16.6666666667% !important;
        }
      }
    }

    img {
      max-width: 100px !important;
      max-height: 100px;
      aspect-ratio: 1;
    }
  }
}

#footer {
  color: $color-accent;
  background-color: $black;
  min-height: unset;
  a {
    color: $color-accent;
  }
  img {
    filter: invert(1);
  }
}

.offcanvas-collapse {
  @include media-breakpoint-up(md) {
    top: 8rem;
  }
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.when-valid {
  background: rgba($darkgray, 0.9);
  transition: all 0.3s;
  .modal & {
    background-color: inherit;
  }
}

.sticky-bottom.when-valid {
  &:not(.is-valid) {
    background-color: $gray-800;
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// Loading Spinner
.loading-spinner-content {
  background: transparent !important;
}
#initial-spinner.spinner-boarder.text-dark {
  color: $color-accent !important;
}

// misc

.modal {
  .btn-link,
  a[color='link'] {
    color: $color-accent;
  }
}

.cart-listing,
.tt-listing {
  color: $color-accent;

  .media-heading {
    a {
      color: $color-accent;
    }
  }

  tr:hover {
    .media-heading a {
      color: $white;
    }
  }
}
